import React, { useState, useEffect } from 'react';
import { Maximize2, List, Tag, Pause, MessageSquare } from 'lucide-react';
import { Eye } from 'react-feather';
import '@fortawesome/fontawesome-free/css/all.min.css';
//import { Topics } from './questiontable';
import { useLocation, useNavigate } from "react-router-dom";
import { Search } from 'lucide-react';
import image1 from '../components/images/Screenshot from 2024-10-31 22-05-14.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const API_BASE_URL = "http://127.0.0.1:3002/api/v1/quiz";

const API_BASE_URL_PROD = "https://api-aviation.technology.dirkk.tech/api/v1/quiz";

const QuizPage = () => {
  const [content, setContent] = useState('AI');
  const [selectedOption, setSelectedOption] = useState(null);
  const [explanation, setQuizzExplanation] = useState('');
 const navigate = useNavigate();
  const location = useLocation();
  const { slug, selectedSubChapters, totalQuestions, sessionId } = location.state || {};
  const [questions, setQuestions] = useState([]);
  const [isTokenValid, setIsTokenValid] = useState(true);
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("id");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [score, setScore] = useState(0);
  const [isCorrect, setIsCorrect] = useState(null);
  const [timeElapsed, setTimeElapsed] = useState(0); // initial time in seconds (2:57:44)
  const [questionColors, setQuestionColors] = useState({});
  const [answeredQuestions, setAnsweredQuestions] = useState([]);//COuleur de question
  const [isSaving, setIsSaving] = useState(false);
  const [isFinishing, setIsFinishing] = useState(false);





  useEffect(() => {
    const timer = setInterval(() => {
        setTimeElapsed((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await fetch(`${API_BASE_URL_PROD}/get-questions`, {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: `Bearer ${token}` 
          },
          body: JSON.stringify({
            slugtopic: slug,
            allsubschapterselected: selectedSubChapters,
            totalQuestions: totalQuestions,
          }),
        });
        const data = await response.json();
        setQuestions(data || []); // Ensure `questions` is always an array
      } catch (error) {
        console.error('Erreur lors de la récupération des questions:', error);
      }
    };

    if (slug && selectedSubChapters && totalQuestions) {
      fetchQuestions();
    }
  }, [slug, selectedSubChapters, totalQuestions, token]);
// Ajoutez un useEffect pour mettre à jour les couleurs lorsque les questions changent
useEffect(() => {
  // Mettre à jour la couleur de la question actuelle selon la réponse
  const currentQuestion = questions[currentQuestionIndex];
  if (answeredQuestions.includes(currentQuestionIndex)) {
    const selectedOptionId = answers[currentQuestion._id];
    const correctOption = currentQuestion.options.find(option => option.isCorrect);
    const isAnswerCorrect = correctOption._id === selectedOptionId;
    const newColor = isAnswerCorrect ? "bg-green-200" : "bg-red-200";
    
    // Mettre à jour les couleurs
    setQuestionColors(prevColors => ({
      ...prevColors,
      [currentQuestionIndex]: newColor,
    }));
  }
}, [currentQuestionIndex, answers, answeredQuestions, questions]);

const saveAnswers = async () => {
  setIsSaving(true);
  try {
    const response = await fetch(`${API_BASE_URL_PROD}/save-answers`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId: userId, 
        quizId: sessionId,
        answers: Object.keys(answers).map((questionId) => ({
          questionId,
          answerId: answers[questionId].selectedOptionId,
          isCorrect: answers[questionId].isAnswerCorrect,
        })),
        totalQuestions, // Envoyer le nombre total de questions
      }),
    });
    const data = await response.json();

    if (response.ok) {
      toast.success(`${data.message}`);
      navigate('/question-bank/test')
      //alert(data.message); // Message de succès ou de quiz terminé
    } else {
      toast.error(`${data.message}`);
      //alert('Erreur : ' + data.message);
    }
  } catch (error) {
    console.error('Erreur lors de la sauvegarde des réponses:', error);
    //alert('Erreur lors de la sauvegarde des réponses.');
    toast.error('Erreur lors de la sauvegarde des réponses:', error);
  } finally {
    setIsSaving(false);
  }
};

const finishAnswers = async () => {
  setIsFinishing(true);
  try {
    const response = await fetch(`${API_BASE_URL_PROD}/save-answers`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId: userId,
        quizId: sessionId,
        answers: Object.keys(answers).map((questionId) => ({
          questionId,
          answerId: answers[questionId].selectedOptionId,
          isCorrect: answers[questionId].isAnswerCorrect,
        })),
        totalQuestions,
      }),
    });
    const data = await response.json();

    if (response.ok) {
      toast.success(`${data.message}`);
    } else {
      toast.error(`${data.message}`);
    }
  } catch (error) {
    console.error('Erreur lors de la sauvegarde des réponses:', error);
    toast.error('Erreur lors de la sauvegarde des réponses:', error);
  } finally {
    setIsFinishing(false);
  }
};

const submitAnswer = async (selectedOptionId) => {
    const currentQuestion = questions[currentQuestionIndex];
    const correctOption = currentQuestion.options.find(option => option.isCorrect);
  
    // If the question has already been answered, prevent re-answering
    if (answers[currentQuestion._id] !== undefined) return;
  
    setSelectedOption(selectedOptionId);
    const isAnswerCorrect = correctOption._id === selectedOptionId;
    setIsCorrect(isAnswerCorrect);
  
    // Store the answer and correctness for the current question
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [currentQuestion._id]: {
        selectedOptionId,
        isAnswerCorrect
      }
    }));
  
    // Update score and question colors
    const newColor = isAnswerCorrect ? "bg-green-200" : "bg-red-200";
    setQuestionColors(prevColors => ({
      ...prevColors,
      [currentQuestionIndex]: newColor,
    }));
    
    if (!answeredQuestions.includes(currentQuestionIndex)) {
      setAnsweredQuestions([...answeredQuestions, currentQuestionIndex]);
    }
  
    if (isAnswerCorrect) {
      setScore(prevScore => prevScore + 1);
      setTimeout(() => {
        handleNext();
      }, 1000);
    } else {
      setTimeout(() => {}, 2000);
    }
};

const handleNext = () => {
  if (currentQuestionIndex < questions.length - 1) {
    const nextIndex = currentQuestionIndex + 1;
    setCurrentQuestionIndex(nextIndex);

    const nextQuestionId = questions[nextIndex]._id;
    const previousAnswer = answers[nextQuestionId];
    if (previousAnswer) {
      setSelectedOption(previousAnswer.selectedOptionId);
      setIsCorrect(previousAnswer.isAnswerCorrect);
    } else {
      setSelectedOption(null);
      setIsCorrect(null);
    }
  } else {
    alert(`Quiz terminé ! Votre score est ${score} sur ${questions.length}`);
  }
  setQuizzExplanation('');
};

const handlePrevious = () => {
  if (currentQuestionIndex > 0) {
    const prevIndex = currentQuestionIndex - 1;
    setCurrentQuestionIndex(prevIndex);

    const prevQuestionId = questions[prevIndex]._id;
    const previousAnswer = answers[prevQuestionId];
    if (previousAnswer) {
      setSelectedOption(previousAnswer.selectedOptionId);
      setIsCorrect(previousAnswer.isAnswerCorrect);
    } else {
      setSelectedOption(null);
      setIsCorrect(null);
    }
  }
  setQuizzExplanation('');
};


 /* const calculateScore = () => {
    let newScore = 0;
    questions.forEach(question => {
      const selectedOptionId = answers[question._id];
      const correctOption = question.options.find(option => option.isCorrect);
      if (correctOption && correctOption._id === selectedOptionId) {
        newScore += 1;
      }
    });
    setScore(newScore);
    alert(`Quiz finished! Your score is ${newScore} out of ${questions.length}`);
  };*/

  useEffect(() => {
  
    if (!token || isTokenExpired(token)) {
      setIsTokenValid(false);
      navigate("/"); // Rediriger vers la page de login si le token est expiré ou manquant
    }
  }, [navigate, token]);

  if (!isTokenValid) {
    return null; // On ne rend rien si le token est invalide pour éviter un affichage avant la redirection
  }

  const isTokenExpired = (token) => {
    if (!token) return true;
  
    const payload = JSON.parse(atob(token.split(".")[1])); // Décoder la partie payload du JWT
    const expirationTime = payload.exp * 1000; // Convertir en millisecondes
    return Date.now() >= expirationTime; // Vérifier si le token est expiré
  };


  const formatTime = () => {
    const hours = String(Math.floor(timeElapsed / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((timeElapsed % 3600) / 60)).padStart(2, '0');
    const seconds = String(timeElapsed % 60).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };    


  const handleContentChange = (newContent) => {
    setContent(newContent);
  };

  /*const capitalizeFirstLetter = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };*/

  return (
    <div className="bg-blue-50 grid grid-cols-1 lg:grid-cols-[3fr_1fr] gap-4 w-full h-screen">
      <div className="space-y-4 h-full px-20">
        <div className="flex justify-between items-center p-4">
          <div className="flex items-center space-x-6">
          <button className="text-green-400">
            <i className="fas fa-thumbtack rotate-45" style={{ fontSize: '22px' }} aria-hidden="true"></i>
            </button>

            <button className="text-yellow-500">
              <i className="fas fa-play fa-2x" aria-hidden="true"></i>
            </button>
            <button className="text-red-500">
              <i className="fas fa-flag fa-2x" aria-hidden="true"></i>
            </button>
          </div>
          <div className="flex items-center space-x-4">
            <button onClick={handlePrevious} className="text-blue-800">
              {'◄ '}
            </button>
            <span className="font-normal">{currentQuestionIndex + 1} / {totalQuestions}</span>
            <button onClick={handleNext} className="text-blue-800">
              {' ►'}
            </button>
          </div>
          <p>{`Ref: ${slug}`}</p>
        </div>

        {questions.length > 0 ? (
        <div className="p-10">
          <h2 className="text-xl mb-8">{questions[currentQuestionIndex].question}</h2>
          <div className="space-y-4">

          {questions[currentQuestionIndex].options.map((option, index) => {
            const isSelected = selectedOption === option._id;
            const isAlreadyAnswered = answers[questions[currentQuestionIndex]._id] !== undefined;
            const wasAnswerCorrect = answers[questions[currentQuestionIndex]._id]?.isAnswerCorrect;

            return (
              <div
                key={option._id}
                onClick={() => !isAlreadyAnswered && submitAnswer(option._id)}
                className={`bg-white py-6 px-4 rounded-full cursor-pointer shadow hover:bg-gray-100 transition-colors ${
                  isSelected
                    ? isCorrect === null
                      ? 'text-blue-500'
                      : isCorrect
                      ? 'text-green-500 border-green-500'
                      : 'text-red-500 border-red-500'
                    : 'text-gray-800'
                } ${
                  !isSelected && wasAnswerCorrect === false && option.isCorrect
                    ? 'text-green-500 border-green-500'
                    : ''
                } ${isAlreadyAnswered ? 'cursor-not-allowed opacity-50' : ''}`}
              >
                <span
                  className={`inline-block w-8 h-8 rounded-full pt-1 mr-4 text-center border ${
                    isSelected
                      ? isCorrect === null
                        ? 'text-blue-500'
                        : isCorrect
                        ? 'text-green-500 border-green-500'
                        : 'text-red-500 border-red-500'
                      : 'text-gray-800'
                  }`}
                >
                  {String.fromCharCode(65 + index)}
                </span>
                {option.text}
              </div>
            );
          })}
                      
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-screen">
            <div className="relative flex items-center justify-center w-16 h-16">
                <div className="w-12 h-12 border-4 border-blue-500 border-solid rounded-full animate-spin border-t-transparent"></div>
            </div>
            <p className="text-gray-500 text-lg font-semibold mt-4">Chargement des questions...</p>
        </div>
      )}

        <div className="fixed bottom-10 left-0 right-0 p-4 flex justify-between items-center max-w-[70%] ml-20 bg-blue-50  rounded-xl">
        <button className="font-semibold bg-white py-3 rounded px-4 text-blue-900" onClick={() => {
          setQuizzExplanation(questions[currentQuestionIndex].explanation)
          handleContentChange('Explanation')}}>
            Explanation
          </button>
      <div className="flex items-center text-blue-900 space-x-4 text-5xl font-light">
        <span>{formatTime()}</span>
      </div>
      <div className="flex space-x-6">
        <button className='p-2'>
          <Maximize2 size={35} className="text-blue-900 hover:bg-white transition rounded p-1" />
        </button>
        <button onClick={() => {handleContentChange('List')}} className='p-2'>
          <List size={35} className="text-blue-900 hover:bg-white transition rounded p-1" />
        </button>
        <button onClick={() => {handleContentChange('Tag')}} className='p-2'>
          <Tag size={35} className="text-blue-900 hover:bg-white transition rounded p-1" />
        </button>
        <button onClick={() => {handleContentChange('Pause')}}className='p-2'>
          <Pause size={35} className="text-blue-900 hover:bg-white transition rounded p-1" />
        </button>
        <button onClick={() => {handleContentChange('MessageSquare')}} className='p-2'>
          <MessageSquare size={35} className="text-blue-900 hover:bg-white transition rounded p-1" />
        </button>
        <button onClick={() => {handleContentChange('Eye')}} className='p-2'>
          <Eye size={35} className="text-blue-900 hover:bg-white transition rounded p-1" />
        </button>
        
      </div>

      </div>
      </div>

      <div className="space-y-4 h-full">
        <div className="bg-white p-4 rounded-lg shadow-lg space-y-4 min-h-screen">
          <div className="p-4 rounded-lg h-full">
          {content === 'AI' && (
              <>
                <div className="flex flex-col items-center mb-4">
                  <div className="relative w-full">
                    <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                      <Search size={20} />
                    </span>
                    <input type="text" placeholder="Ask anything" className="w-full p-2 border rounded-lg pl-10" />
                  </div>
                  <span className="font-semibold text-black mb-3 mt-3">AI</span>
                  <div className="w-24 h-24 bg-blue-200 rounded-full mt-4 flex items-center justify-center">
                    <img src={image1} alt="AI Icon" className="w-24 h-24 rounded-full" />
                  </div>
                </div>
              </>
          )}
            {content === 'List' && (
                <div>
                  <h3 className="font-semibold mb-5">List Content</h3>
                  <div className="grid grid-cols-5 gap-2">
                    {Array.from({ length: totalQuestions }, (_, i) => {
                      const number = i + 1;

                      // Vérifier si la question a été répondue
                      const hasAnswered = answeredQuestions.includes(i);
                      
                      // Vérifier si la question est celle en cours
                      const isCurrentQuestion = i === currentQuestionIndex;

                      // Définir la couleur de fond
                      let bgColor = "bg-white";

                      if (hasAnswered) {
                        // Si la réponse est correcte pour cette question, mettre en vert
                        bgColor = answers[questions[i]._id]?.isAnswerCorrect ? "bg-green-200" : "bg-red-200";
                      }
                      
                      if (isCurrentQuestion) {
                        // Prioriser la couleur de la question en cours si elle est sélectionnée
                        bgColor = selectedOption !== null 
                          ? (isCorrect ? "bg-green-200" : "bg-red-200")
                          : bgColor;
                      }

                      return (
                        <span
                          key={i}
                          className={`${bgColor} text-black text-center py-2 px-5 rounded shadow`}
                        >
                          {number}
                        </span>
                      );
                    })}
                  </div>
                  <div className='py-10'>
                     {/* Bouton Finish */}
                  <button
                      className="bg-blue-900 text-white hover:bg-white hover:text-blue-900 px-12 py-3 rounded-md text-lg font-semibold border"
                      onClick={finishAnswers}
                      disabled={isFinishing} // Désactiver le bouton pendant la sauvegarde
                    >
                      {isFinishing ? (
                        <span className="flex items-center">
                          <svg
                            className="animate-spin h-5 w-5 text-white mr-3"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291l2.293-2.292-1.414-1.414L4 15.292V20h4v-2h-2.586z"
                            ></path>
                          </svg>
                          End...
                        </span>
                      ) : (
                        "Finish"
                      )}
                    </button>
                  </div>
                  <div>
                    {/* Bouton Save */}
                      <button
                        className="bg-gray-400 text-white px-12 py-3 rounded-md text-lg font-semibold hover:bg-blue-900"
                        onClick={saveAnswers}
                        disabled={isSaving}
                      >
                        {isSaving ? (
                          <span className="flex items-center">
                            <svg
                              className="animate-spin h-5 w-5 text-white mr-3"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291l2.293-2.292-1.414-1.414L4 15.292V20h4v-2h-2.586z"
                              ></path>
                            </svg>
                            Sauvegarde...
                          </span>
                        ) : (
                          "Save"
                        )}
                      </button>
                  </div>
                </div>
            )}
            {content === 'Tag' && (
              <div>
                <h3 className="font-semibold">Tag Content</h3>
                <p>Information related to tagging.</p>
              </div>
            )}
            {content === 'Pause' && (
              <div>
                <h3 className="font-semibold">Similar Questions</h3>
              </div>
            )}
            {content === 'Explanation' && (
              <div>
                <h3 className="font-semibold mb-6 text-center">Explanation Content</h3>
                <p>{explanation}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Composant pour afficher les notifications */}
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </div>
  );
};

export default QuizPage;
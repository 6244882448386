import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Sidebar from './components/common/Sidebar';
import HomePage from './pages/HomePage';
import QuizTestPage from './pages/QuizTestPage';
import TestPage from './pages/TestPage';
import CoursPage from './pages/CoursPage';
import Profil from './pages/Profil';
import Connexion from './pages/Connexion';
import Inscrire from './pages/Inscrire';
import SidebarLeftTestPage from './components/common/SidebarLeftTestPage';
import Communication from './pages/Communication';
import TestPages from './pages/TestPages';

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();
  const showSidebar = location.pathname !== '/' && location.pathname !== '/Inscrire';

  return (
    <div className="flex bg-gray-100">
      {showSidebar && <Sidebar />}
      <MainContent showSidebar={showSidebar} />
    </div>
  );
}

function MainContent({ showSidebar }) {
  const location = useLocation();
  const isTestPage = location.pathname.startsWith('/question-bank/test');
  const paddingClass = isTestPage ? '' : 'py-8 ';

  return (
    <main className={`flex-1 ${showSidebar ? 'ml-16' : ''} min-h-screen ${paddingClass}`}>
      <Routes>
        <Route path='/sidebar' element={<SidebarLeftTestPage/>} />
        <Route path="/" element={<Connexion />} />
        <Route path="/Communication" element={<Communication />} />
        <Route path="/Homepage" element={<HomePage />} />
        <Route path="/quiz/:subject" element={<QuizTestPage />} />
        <Route path="/stats" element={<div className="container mx-auto">Page Statistiques</div>} />
        <Route path="/profile" element={<div className="container mx-auto">Page Profil</div>} />
        <Route path="/courses" element={<CoursPage />} />
        <Route path="/grades" element={<div className="container mx-auto">Page Grades</div>} />
        <Route path="/question-bank/test" element={<TestPage />} />
        <Route path="/aviation-news" element={<div className="container mx-auto">Page Aviation News</div>} />
        <Route path="/flights" element={<div className="container mx-auto">Page Flights</div>} />
        <Route path="/agenda" element={<div className="container mx-auto">Page Agenda</div>} />
        <Route path="/customer-service" element={<div className="container mx-auto">Page Customer Service</div>} />
        <Route path="/messages" element={<div className="container mx-auto">Page Messages</div>} />
        <Route path="/call-meeting" element={<div className="container mx-auto">Page Call Meeting</div>} />
        <Route path="/Profil" element={<Profil />} />
        <Route path="/Inscrire" element={<Inscrire />} />
        <Route path='/test' element={<TestPages/>} />
      </Routes>
    </main>
  );
}

export default App;
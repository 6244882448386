import React, {useEffect,  useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';  

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//const API_BASE_URL = "http://127.0.0.1:3002/api/v1/quiz";
const API_BASE_URL_PROD = "https://api-aviation.technology.dirkk.tech/api/v1/quiz";



export default function SidebarLeftTestPage() {
    const [showRoseDiv, setShowRoseDiv] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [score] = useState(82);
    const navigate = useNavigate();
    const [isOperationSelected, setIsOperationSelected] = useState(false);
    const [isContainerExpanded, setIsContainerExpanded] = useState(false); 
    const [searchContainerWidth, setSearchContainerWidth] = useState('w-0'); 
    const [showText, setShowText] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = 3; // Nombre total de pages
    const [recentQuizzes, setRecentQuizzes] = useState([]);
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("id");
    const [error, setError] = useState('');
    

        useEffect(() => {
          const fetchRecentQuizzes = async () => {
            try {
              const response = await axios.get(`${API_BASE_URL_PROD}/quizzes/recent/${userId}`, {
                headers: {
                  "Authorization": `Bearer ${token}`,
                },
              });
              setRecentQuizzes(response.data.value);
            } catch (error) {
                setError('Erreur lors de la récupération des réponses.', error);
                //res.status(500).json({message: error});
              //console.error('Erreur lors de la récupération des réponses', error);
            }
          };
      
          fetchRecentQuizzes();
        }, [token, userId]);


    const handleSearchClick = () => {
        if (selectedOption === "option1" || selectedOption === "option2") {
            setSearchContainerWidth(isContainerExpanded ? 'w-0' : 'w-3/4');
            setIsContainerExpanded(!isContainerExpanded);
            setShowText(!showText);
            if (selectedOption === "option2") setShowRoseDiv(true);
        }
    };

    const handlePageChange = (direction) => {
        if (direction === 'next' && currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        } else if (direction === 'previous' && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className="bg-gray-100 pt-10 rounded-lg w-full max-w-xs transition-all duration-300 ease-in-out shadow-lg">
            <div className="flex justify-end space-x-4 pr-4 mb-5">
                {["microphone", "notification", "user"].map((icon, idx) => (
                    <button key={idx} className="text-gray-500 hover:text-gray-700">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                            {icon === "microphone" && (
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 12c2.762 0 5-2.238 5-5S14.762 2 12 2 7 4.238 7 7s2.238 5 5 5zm0 1c-2.708 0-7 1.29-7 3.75V18h14v-1.25c0-2.46-4.292-3.75-7-3.75z" />
                            )}
                            {icon === "notification" && (
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405C18.215 14.215 17 12.21 17 10V8a5 5 0 10-10 0v2c0 2.21-1.215 4.215-3.595 5.595L4 17h5m1 0v1a2 2 0 11-4 0v-1" />
                            )}
                            {icon === "user" && (
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5.121 18.364a1 1 0 01-.364-1.121C5.991 14.68 8.5 13 12 13s6.009 1.68 7.243 4.243a1 1 0 01-.364 1.121M12 13a4 4 0 100-8 4 4 0 000 8z" />
                            )}
                        </svg>
                    </button>
                ))}
            </div>

            
            <h2 className="text-2xl font-semibold text-gray-900 text-center mb-8">Search Question</h2>

            <div className="space-y-6 mb-6 px-6">
                <select className="w-full bg-white border border-gray-300 rounded py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500" onChange={(e) => setSelectedOption(e.target.value)}>
                    <option value="">Syllabus</option>
                    <option value="option1">Operation</option>
                    <option value="option2">Meteorology</option>
                </select>
                <select className="w-full bg-white border border-gray-300 rounded py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500">
                    <option value="">Subject 1</option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                </select>
                <select className="w-full bg-white border border-gray-300 rounded py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500">
                    <option value="">Answer</option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                </select>

                <div className="flex items-center border border-gray-300 rounded-lg overflow-hidden w-full mt-4 shadow-sm">
                    <button className="px-3 text-gray-500">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-4.35-4.35M16.95 11A6 6 0 1 0 5 11a6 6 0 0 0 11.95 0z" />
                        </svg>
                    </button>
                    <input type="text" placeholder="Search..." className="w-full px-3 py-2 focus:outline-none" />
                </div>

                <button onClick={handleSearchClick} className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-700 focus:outline-none shadow-md transition-colors duration-200 mt-6">Search</button>

               {/*} <h3 className="text-xl text-gray-900 text-center mt-8 font-medium">Last Saved Tests</h3>

                <div className="space-y-4 mt-4 text-center">
                    {["Air Law 5/10 - 22:53", "Meteorology 13/01 - 13:10", "Communication 08/01 - 08:05", "Air Law 11/01 - 16:37"].map((test, idx) => (
                        <p key={idx} className="text-gray-700">{test}</p>
                    ))}
                </div>*/}
                <h3 className="text-xl text-gray-900 text-center mt-8 font-medium">Last Saved Tests</h3>
                <div className="space-y-4 mt-4 text-center">
                {recentQuizzes.map((quiz, idx) => (
                    <p key={idx} className="text-gray-700 hover:bg-red-300">
                    {`Topic: ${quiz.slugTopic} - Score: ${quiz.score}/${quiz.totalQuestions} - ${new Date(quiz.lastUpdated).toLocaleString()}`}
                    </p>
                ))}
                {error && <p className="mt-4 text-red-500">{error}</p>}
                </div>

                
                <button className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-700 focus:outline-none shadow-md transition-colors duration-200 mt-6">More</button>
            </div>

            <div className={`bg-gray-100 ${searchContainerWidth} fixed top-0 right-0 bottom-0 text-white pt-2 rounded-lg transition-all duration-300`}>
                <div className="flex flex-col items-center mx-4 my-8 space-y-4">
                    <button onClick={handleSearchClick} className="bg-blue-900 text-white font-bold py-2 px-4 rounded-full">Close</button>
                    {showText && (
                        <>
                            {selectedOption === 'option1' && (
                                <div>
                                    <p className="text-gray-800 text-2xl text-center mb-4">Searched Questions</p>
                                    {["Question 1", "Question 2", "Question 3"].map((question, idx) => (
                                        <button key={idx} className="bg-white text-black py-4 px-4 rounded-lg mb-3 w-full shadow-sm">{question}</button>
                                    ))}
                                </div>
                            )}
                            {selectedOption === 'option2' && (
                                <div>
                                    <p className="text-gray-800 text-2xl text-center mb-4">Last Saved Tests</p>
                                    {["Test 1", "Test 2", "Test 3"].map((test, idx) => (
                                        <button key={idx} className="bg-white text-black py-3 px-4 rounded-lg mb-3 w-full shadow-sm">{test}</button>
                                    ))}
                                </div>
                            )}
                            <div className="flex justify-center items-center mt-4">
                                <button onClick={() => handlePageChange('previous')} className="bg-blue-900 text-white px-4 py-2 rounded-full mx-2">&lt;</button>
                                <p className="text-gray-700 text-lg">Page {currentPage}/{totalPages}</p>
                                <button onClick={() => handlePageChange('next')} className="bg-blue-900 text-white px-4 py-2 rounded-full mx-2">&gt;</button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

import React, { useState } from 'react'; 
import { Link } from 'react-router-dom';
import logoImage from '../images/iconFly.png'; // Chemin de votre logo
import titleImage from '../images/logoflyby.png'; // Remplacez par le chemin de votre image de titre

const Sidebar = () => {
  const [showText, setShowText] = useState(false);
  const [activeLink, setActiveLink] = useState("/"); // État pour suivre le lien actif

  const handleMouseEnter = () => {
    setShowText(true);
  };

  const handleMouseLeave = () => {
    setShowText(false);
  };

  const handleLinkClick = (link) => {
    setActiveLink(link); 
  };

  return (
    <nav
    className={`bg-blue-900 ${showText ? 'w-64' : 'w-20'} text-white py-4 h-screen fixed top-0 left-0 transition-all duration-400 overflow-hidden`} // Ajoutez overflow-hidden ici
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
  >
    <div className={`text-center mb-8 flex flex-col items-center pr-4 justify-center`}>
      {showText ? (
        <img src={titleImage} alt="Titre" className="h-12 w-auto mb-2" /> // Image du titre
      ) : (
        <img src={logoImage} alt="Logo" className="h-14 w-14 ml-1" /> // Augmentez la taille de l'image ici
      )}
      {showText && <span className="text-white text-sm">Academy Aviation</span>} 
    </div>
    <ul className="space-y-4">
      {[
        { to: "/", icon: "dashboard", label: "Dashboard" },
        { to: "/courses", icon: "book", label: "Courses" },
        { to: "/grades", icon: "school", label: "Grades" },
        { to: "/question-bank/test", icon: "quiz", label: "Question Bank" },
        { to: "/aviation-news", icon: "flight_takeoff", label: "Aviation News" },
        { to: "/flights", icon: "flight", label: "Flights" },
        { to: "/agenda", icon: "event", label: "Agenda" },
        { to: "/customer-service", icon: "support_agent", label: "Customer Service" },
        { to: "/messages", icon: "message", label: "Messages" },
        { to: "/call-meeting", icon: "call", label: "Call Meeting" },
      ].map(({ to, icon, label }) => (
        <li 
          key={label} 
          className={`pl-0 pr-0 py-2 hover:bg-blue-700 flex justify-center ${activeLink === to ? 'bg-white bg-opacity-20 border-l-4 border-white border-opacity-70 rounded-none' : ''}`} // Supprime les marges internes gauche et droite pour coller aux bords
        >
          <Link to={to} className="flex pl-5 w-full" onClick={() => handleLinkClick(to)}>
            <span 
              className="material-icons"
              style={{ 
                fontSize: '2rem', 
                color: activeLink === to ? 'white' : 'rgba(255, 255, 255, 0.5)',
                marginLeft: showText ? '0.5rem' : '0', // Ajout d'une marge à gauche lorsque showText est vrai
              }}
            >
              {icon}
            </span>
            {showText && <span className="ml-2 text-lg">{label}</span>} {/* Espacement pour le texte */}
          </Link>
        </li>
      ))}
    </ul>
  </nav>
  
  );
};

export default Sidebar;
